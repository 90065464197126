<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    authority="Congress_Management_Congress_Register_Period"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './registrationPeriodsStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      periodOptions: [],
    }
  },
  computed: {
    congressID() {
      return this.$route.params.id
    },
    config() {
      return {
        store: storeModule,
        module: 'congress-register-periods',
        endpoint: 'congress-register-periods',
        route: 'congress-register-periods',
        title: {
          single: this.$t('Congress Registration Period'),
          plural: this.$t('Congress Registration Periods'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formFields() {
      return [
        {
          id: 'congress',
          type: 'hidden',
          initVal: { id: this.congressID },
          hidden: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
          rules: 'min:3',
        },
        // {
        //   id: 'registrationPeriod',
        //   object: true,
        //   type: 'select',
        //   label: this.$t('registrationPeriod'),
        //   options: this.periodOptions,
        //   required: true,
        // },
        {
          id: 'endDate',
          type: 'date',
          label: this.$t('deadline date'),
          required: true,
        },
        {
          id: 'maxRegistrants',
          type: 'number',
          label: this.$t('maxRegistrants'),
          rules: 'positive',
        },
        {
          id: 'availableOnline',
          type: 'checkbox',
          label: this.$t('availableOnline'),
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
        },
      ]
    },
  },
  // mounted() {
  //   this.$store.dispatch('app-common/fetchRegistrationPeriods').then(response => {
  //     this.periodOptions = response.data
  //   })
  // },
}
</script>
